<!-- 搜索窗 -->
<template>
  <div class="search" v-show="visible">
    <div>
      <div class="close">
        <a-button icon="close" @click="close"></a-button>
      </div>
      <a-input-search
        @pressEnter="onSearch"
        v-model="noticeTitle"
        placeholder="请输入新闻标题"
        style="width: 100%"
        @search="onSearch"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "searchPopup",
  data() {
    return {
      visible: false,
      noticeTitle: "",
    };
  },
  methods: {
    openSearch() {
      this.visible = true;
      this.forbidBodyScoll();
    },
    onSearch() {
      this.visible = false;
      document.body.parentNode.style.overflow = "auto";
      this.$router.push({
        path: "/searchResult",
        query: {
          noticeTitle: this.noticeTitle,
        },
      });
    },
    close() {
      this.visible = false;
      document.body.parentNode.style.overflow = "auto";
    },
    //禁止浏览器窗口滚动
    forbidBodyScoll() {
      document.body.parentNode.style.overflow = "hidden"; //隐藏且禁用横向纵向两个滚动条
    },
  },
};
</script>
<style lang='less' scoped>
.search {
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  z-index: 999;

  > div {
    border-bottom: 2px solid #fff;
    width: 80%;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%);
    > .close {
      display: flex;
      justify-content: end;
      /deep/ .ant-btn {
        background-color: transparent;
        border: 0;
      }
    }
    /deep/ .ant-input-search {
      margin-top: 50px;
      // padding-left: 30px;
      > input {
        height: 50px;
        background-color: transparent;
        border: 0;
        color: #fff;
      }
    }
    /deep/ .ant-input-suffix {
      right: 30px !important;
    }
    /deep/ i {
      font-size: 18px;
      color: #fff;
    }
  }
}
</style>