<template>
  <div class="newNotice">
    <div class="content">
      <div v-for="(item, index) of data.rows" :key="item.noticeId" class="item">
        <div class="img">
          <img :src="item.noticePicture" key="item.noticePicture" alt="" />
        </div>
        <div class="itemContent">
          <div>
            <p class="title">{{ item.noticeTitle }}</p>
            <p class="time">{{ item.createTime }}</p>
          </div>
          <p class="noticeSubtitle">{{ item.noticeSubtitle }}</p>
          <p>
            <span @click="goToDetail(item.noticeId, index, id)"
              >更多&gt;&gt;</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="pager">
      <div class="current">当前{{ pageNum }}/{{ total }}页</div>
      <a-pagination :model="pageNum" :total="total * 10" @change="pageChange" />
      <div class="end" @click="endPage">尾页</div>
    </div>
  </div>
</template>

<script>
import { getData } from "@/api/api.js";
export default {
  name: "my-notice",
  props: {
    id: {
      type: Number,
      default: 5,
    },
    noticeTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pageNum: 1,
      data: "",
      totalNum: 0,
    };
  },
  mounted() {},
  methods: {
    pageChange(page) {
      this.pageNum = page;
      this.queryData();
    },
    //尾页
    endPage() {
      if (this.data !== "") {
        this.pageNum = Math.ceil(this.data.total / 3);
        this.queryData();
      }
    },
    //跳转详情页面
    goToDetail(noticeId, index, id) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          noticeId,
          index,
          id,
          total: this.totalNum,
        },
      });
    },
    queryData() {
      if (this.id === 5) {
        getData({
          pageNum: this.pageNum,
          pageSize: 10,
          noticeTitle: this.noticeTitle,
        }).then((res) => {
          this.data = res.data;
          this.totalNum = res.data.total;
        });
      } else {
        getData({
          noticeType: this.id,
          pageNum: this.pageNum,
          pageSize: 10,
        }).then((res) => {
          this.data = res.data;
          this.totalNum = res.data.total;
        });
      }
    },
  },
  watch: {
    $route: {
      handler() {
        this.queryData();
      },
      immediate: true,
    },
  },
  computed: {
    total() {
      return this.data !== "" ? Math.ceil(this.data.total / 3) : 1;
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and(min-width: 1200px) {
  .newNotice {
    width: 68%;
  }
}
@media screen and(max-width: 1200px) {
  .newNotice {
    width: 90%;
  }
}
@media screen and(max-width: 810px) {
  .newNotice {
    .content {
      > .item {
        flex-wrap: wrap;
        > .img {
          height: auto !important;
        }
        > div {
          width: 100% !important;
        }
        > .itemContent {
          > div {
            flex-wrap: wrap;
            > .time {
              width: 100%;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
.newNotice {
  // width: 100%;
  padding: 50px 0;
  margin: auto;
  > .content {
    margin: auto;
    > .item {
      display: flex;
      border-bottom: 1px solid rgb(190, 187, 187);
      padding: 20px 0;
      > .img {
        overflow: hidden;
        width: 240px;
        height: 160px;
        > img {
          transform: scale(1);
          transition: 0.5s;
          width: 100%;
        }
        > img:hover {
          transform: scale(1.2);
          transition: 0.5s;
        }
      }
      > .itemContent {
        width: 80%;
        padding: 15px;
        > div {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          .title {
            font-size: 14px;
            font-weight: bold;
          }
          .title:hover {
            color: #d20505;
          }
          .time {
            font-size: 1.3rem;
            color: #333333;
          }
        }
        .noticeSubtitle {
          text-indent: 25px;
          margin: 10px 0 !important;
        }
        > :last-child {
          text-align: right;
          > span {
            cursor: pointer;
            color: #d20505;
            font-weight: bold;
          }
        }
      }
    }
  }
  > .pager {
    margin-top: 30px;
    display: flex;
    justify-content: right;
    > * {
      margin-left: 10px;
    }
    > .current {
      height: 32px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      line-height: 32px;
      padding: 0 10px;
    }
    > .end {
      height: 32px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      line-height: 32px;
      padding: 0 10px;
    }
    .current:hover,
    .end:hover {
      color: #1890ff;
      border: 1px solid #1890ff;
    }
  }
}
</style>

<style lang="less">
.text {
  * {
    text-indent: 25px;
    // color: #666666 !important;
    // font-size: 1.4rem;
  }
}
</style>