<template>
  <div class="home">
    <div class="carousel">
      <a-carousel arrows autoplay dotsClass="slick-dots">
        <div
          slot="prevArrow"
          class="custom-slick-arrow"
          style="left: 10px; z-index: 1"
        >
          <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow" class="custom-slick-arrow" style="right: 40px">
          <a-icon type="right-circle" />
        </div>
        <div v-for="item of bannerList" :key="item">
          <img :src="item" :key="item" alt="" class="imgBoxTag" />
        </div>
      </a-carousel>
      <div class="text" v-html="pageContent"></div>
      <!-- 视频 -->
      <div class="video">
        <video
          src="@/assets/img/Home/jinChangHome.mp4"
          controls="controls"
          muted
          autoplay
        ></video>
      </div>
    </div>

    <div class="service">
      <div class="content">
        <div
          v-for="(item, index) of serviceList"
          :key="index"
          :style="`background-image:url('${item.bg}')`"
          :class="item.id === num ? 'stretching' : ''"
          @mouseover="num = item.id"
        >
          <div class="title">
            <p>{{ item.title1 }}</p>
            <p>{{ item.title2 }}</p>
          </div>
          <p>{{ item.name }}</p>
          <p>{{ item.content }}</p>
          <div class="arrow">
            <router-link :to="item.url">
              <img :src="require('@/assets/img/Home/arrow.png')" alt="" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="modular">
      <div class="content modular_content">
        <div
          v-for="item of data"
          :key="item.id"
          class="item"
          @click="jump(item.url)"
        >
          <div>
            <p>
              <span style="color: #333333">{{ item.title }}</span>
              <span style="color: #999999">{{ item.subtitle }}</span>
            </p>
            <div
              class="itemContent"
              v-for="(listItem, index) of item.list"
              :key="listItem.id"
            >
              <div class="img">
                <img
                  :src="listItem.noticePicture"
                  :key="listItem.noticePicture"
                  alt=""
                />
              </div>
              <div>
                <p>{{ listItem.noticeTitle }}</p>
                <p class="text">{{ listItem.noticeSubtitle }}</p>
                <p
                  class="gd"
                  @click.stop="
                    goToDetail(listItem.noticeId, index, item.id, item.total)
                  "
                >
                  <span>更多&gt;&gt;</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce">
      <div class="content">
        <div>
          <img :src="require('@/assets/img/Home/introduce1.png')" alt="" />
          <div>
            <p>宜方达物业</p>
            <p>金昌市宜方达物业管理有限责任公司</p>
          </div>
        </div>
        <div>
          <img :src="require('@/assets/img/Home/introduce2.png')" alt="" />
          <div>
            <p>宜方达物业简介</p>
            <p>
              成立于2018年8月1日，隶属市政府国资委管理，集物业
              管理、民用供暖、民用给排水、公共设施供电...
              <span
                ><router-link to="/about/introduction">更多</router-link>
              </span>
            </p>
            <ul>
              <li>
                <h1>163</h1>
                <p>
                  员工数量<br />
                  (人)
                </p>
              </li>
              <li>
                <h1>357.88</h1>
                <p>
                  服务面积<br />
                  (万㎡)
                </p>
              </li>
              <li>
                <h1>48433</h1>
                <p>
                  覆盖人群<br />
                  (户)
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <img :src="require('@/assets/img/Home/introduce3.png')" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, queryPageManageList } from "@/api/api.js";
export default {
  name: "Home",
  data() {
    return {
      pageContent: "",
      num: 3,
      data: [
        {
          id: 1,
          total: 0,
          url: "/journalism/industry",
          title: "行业新闻",
          subtitle: "INDUSTRY NEWS",
          list: [],
        },
        {
          id: 2,
          total: 0,
          url: "/journalism/company",
          title: "公司新闻",
          subtitle: "COMPANY NEWS",
          list: [],
        },
        {
          id: 3,
          total: 0,
          url: "/journalism/community",
          title: "社区动态",
          subtitle: "COMMUNITY DYNAMICS",
          list: [],
        },
        {
          id: 4,
          total: 0,
          url: "/party",
          title: "社区党建",
          subtitle: "COMMUNITY PARTY BUILDING",
          list: [],
        },
      ],
      swiperOptions: {
        // direction: "vertical",
        pagination: {
          el: ".swiper-pagination",
        },
      },
      bannerList: [],
      serviceList: [
        {
          id: 1,
          title1: "PROPERTY",
          title2: "SERVICES",
          name: "物业服务",
          content: "提供温馨、敬业的物业服务，共筑美好未来",
          bg: require("@/assets/img/Home/wy.png"),
          url: "/system/property",
        },
        {
          id: 2,
          title1: "ENGINEERING",
          title2: "MAINTENANCE",
          name: "工程维修",
          content: "对小区内设施进行快速维修，保障住户生活",
          bg: require("@/assets/img/Home/gc.png"),
          url: "/system/build",
        },
        {
          id: 3,
          title1: "CONVENIENT",
          title2: "SERVICE",
          name: "便民服务",
          content: "为住户生活需求提供便利服务",
          bg: require("@/assets/img/Home/dj.png"),
          url: "/system/convenience",
        },
        {
          id: 4,
          title1: "YIMIN",
          title2: "OPTIMIZATION",
          name: "宜民优选",
          content: "为住户提供优质的商品",
          bg: require("@/assets/img/Home/bm.png"),
          url: "/system/preferred",
        },
      ],
    };
  },
  created() {
    this.getModular();
    this.getBannerList();
  },
  mounted() {
    //监听滚动条
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    //获取轮播图列表
    getBannerList() {
      queryPageManageList({ pageName: "首页" }).then((res) => {
        if (res.data.rows[0]) {
          this.bannerList = res.data.rows[0].pagePicture.split(",");
          this.pageContent = res.data.rows[0].pageContent;
        }
      });
    },
    //跳转详情页面
    goToDetail(noticeId, index, id, total) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          noticeId,
          index,
          id,
          total,
        },
      });
    },
    getRect(element) {
      var rect = element.getBoundingClientRect(); // 距离视窗的距离
      var top = document.documentElement.clientTop
        ? document.documentElement.clientTop
        : 0; // html元素对象的上边框的宽度
      var left = document.documentElement.clientLeft
        ? document.documentElement.clientLeft
        : 0;
      return {
        top: rect.top - top,
        bottom: rect.bottom - top,
        left: rect.left - left,
        right: rect.right - left,
      };
    },
    //获取新闻信息
    async getModular() {
      await this.data.forEach((item) => {
        getData({
          noticeType: item.id,
          pageNum: 1,
          pageSize: 2,
        }).then((res) => {
          item.list = res.data.rows;
          item.total = res.data.total;
        });
      });
    },
    //跳转新闻模块
    jump(url) {
      this.$router.push(url);
    },
    /**
     * 监听元素是否出现在视窗内
     */
    handleScroll() {
      const modular = document.querySelector(".modular_content");
      var windowH =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight; // 浏览器高度兼容写法
      var obj = this.getRect(modular);
      // console.log(obj, windowH);
      if (obj.top < windowH && obj.bottom >= windowH) {
        const element = document.querySelectorAll(".item");
        element.forEach((item, index) => {
          item.style.setProperty("--animate-duration", `2s`);
          if (index % 2 === 0) {
            item.classList.add("animate__animated", "animate__fadeInLeft");
          } else {
            item.classList.add("animate__animated", "animate__fadeInRight");
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and(min-width:1301px) {
  .home {
    .carousel {
      // .video {
      //   position: absolute;
      //   bottom: 20px;
      //   right: 30px;
      // }
    }
    .content {
      width: 75%;
    }
    /deep/.carousel {
      .ant-carousel {
        img {
          height: 910px !important;
        }
        .slick-track {
          height: 910px !important;
        }
      }
    }
  }
}
@media screen and(max-width:1300px) {
  .home {
    .carousel {
      // .video {
      //   position: absolute;
      //   bottom: 20px;
      //   right: 30px;
      // }
      .text {
        left: 5% !important;
      }
    }
    .content {
      width: 80%;
    }
    /deep/.carousel {
      .ant-carousel {
        img {
          height: 720px !important;
        }
        .slick-track {
          height: 720px !important;
        }
      }
    }
  }
}
@media screen and(max-width:1128px) {
  .home {
    .carousel {
      .text {
        > div {
          font-size: 30px !important;
        }
        left: 3% !important;
      }
    }
    .content {
      width: 100%;
    }
    /deep/.carousel {
      .ant-carousel {
        img {
          height: 620px !important;
        }
        .slick-track {
          height: 620px !important;
        }
      }
    }
  }
}
@media screen and(max-width:974px) {
  .home {
    .carousel {
      .text {
        > div {
          font-size: 25px !important;
          letter-spacing: 10px !important;
        }

        // top: 15% !important;
      }
      // .video {
      //   border-radius: 0 !important;
      //   position: relative;
      //   bottom: 0 !important;
      //   right: 0 !important;
      //   > video {
      //     width: 100% !important;
      //     height: 400px !important;
      //   }
      // }
    }
    .service {
      padding: 30px 15px !important;
      .content {
        flex-wrap: wrap;
        > div {
          width: 50% !important;
        }
      }
    }
    .modular {
      padding: 30px 15px !important;
      .content {
        > div {
          width: 100% !important;
        }
        .img {
          max-height: 120px !important;
        }
      }
    }
    .introduce {
      > .content {
        > :last-child {
          display: none;
        }
      }
    }
    /deep/.carousel {
      .ant-carousel {
        img {
          height: 530px !important;
        }
        .slick-track {
          height: 530px !important;
        }
      }
    }
  }
}
@media screen and(max-width:745px) {
  .home {
    .carousel {
      .text {
        // top: 15% !important;
        > div {
          font-size: 20px !important;
        }
      }
    }
    .introduce {
      > .content {
        flex-wrap: wrap;
        > div {
          width: 100%;
          p {
            margin: 0%;
          }
        }
      }
    }
    /deep/.carousel {
      .ant-carousel {
        img {
          height: 420px !important;
        }
        .slick-track {
          height: 420px !important;
        }
      }
    }
  }
}
@media screen and(max-width:540px) {
  .home {
    .carousel {
      .text {
        display: none !important;
        // letter-spacing: 8px !important;
        // font-size: 15px !important;
        // top: 10% !important;
      }
    }
    .introduce {
      > .content {
        > :nth-child(2) {
          > :nth-child(2) {
            > p {
              width: auto !important;
            }
            > ul {
              display: none;
            }
          }
        }
      }
    }
    .modular {
      > .content {
        .itemContent {
          flex-wrap: wrap;
          > * {
            width: 100% !important;
          }
        }
        .img {
          max-height: 300px !important;
        }
      }
    }
    /deep/.carousel {
      .ant-carousel {
        img {
          height: 310px !important;
        }
        .slick-track {
          height: 310px !important;
        }
      }
    }
  }
}

.stretching {
  width: 400px !important;
}
.reduction {
  transform: scale(1);
  overflow: hidden;
  transition: 0.5s;
}

.home {
  .carousel {
    position: relative;
    .ant-carousel {
      .custom-slick-arrow:before {
        display: none;
      }
      .custom-slick-arrow {
        width: 25px;
        height: 25px;
        background-color: rgba(31, 45, 61, 0.11);
        opacity: 0.2;
        > * {
          font-size: 50px;
          color: #fff;
        }
      }
      .custom-slick-arrow:hover {
        opacity: 0.6 !important;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .video {
      border-radius: 5px;
      overflow: hidden;
      z-index: 999999;
      position: fixed;
      bottom: 0 !important;
      left: 0 !important;
      > video {
        width: 350px;
        height: 200px;
      }
    }
    .text {
      position: absolute;
      top: 35%;
      left: 8%;
      display: block;
      animation: fadeInUp;
      animation-duration: 2s;
    }
  }

  .service {
    width: 100%;
    padding: 40px;
    background: #fef7f7;
    > .content {
      margin: auto;
      display: flex;
      > div {
        .reduction();
        position: relative;
        width: 25%;
        height: 240px;
        padding: 20px;
        background-size: cover;
        .title {
          margin-bottom: 20px;
          > p {
            margin: 0;
            padding: 0;
            width: 71px;
            font-size: 1.4rem;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            color: #ffffff;
          }
        }
        > :nth-child(2) {
          font-size: 28px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ffffff;
        }
        > :nth-child(3) {
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ffffff;
          display: none;
        }
        > .arrow {
          display: none;
          position: absolute;
          bottom: 30px;
          width: 25px;
          img {
            width: 100%;
          }
        }
      }
      > div:hover {
        // transform: scale(1.05);
        z-index: 1;
        overflow: hidden;
        transition: 0.5s;
        > :nth-child(3) {
          display: block;
          animation: fadeInRight;
          animation-duration: 1s;
        }
        > .arrow {
          display: block;
          animation: fadeInRight;
          animation-duration: 2s;
        }
      }
    }
  }
  .modular {
    width: 100%;
    padding: 40px 40px 20px 40px;
    > .content {
      margin: auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 49%;
        margin-bottom: 2%;
        > div {
          height: 100%;
          .reduction();
          cursor: pointer;
          background: #ffffff;
          border-radius: 4px;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
          padding: 20px;
          > p {
            padding-bottom: 10px;
            border-bottom: 2px solid #dddddd;
            display: flex;
            justify-content: space-between;
            span {
              font-size: 16px;
              font-weight: 700;
            }
          }
          > :last-child {
            margin-top: 20px;
          }
          > .itemContent {
            display: flex;
            justify-content: space-between;
            > .img {
              overflow: hidden;
              width: 200px;
              max-height: 100px;
              img {
                .reduction();
                width: 100%;
                height: auto;
                object-fit: cover;
              }
            }
            > .img:hover {
              img {
                transform: scale(1.2);
                z-index: 1;
                overflow: hidden;
                transition: 0.5s;
              }
            }
            > :last-child {
              padding: 10px 10px 0 10px;
              width: 80%;
              > :first-child {
                font-size: 14px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                font-weight: bold;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
              .text {
                font-size: 12px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                text-align: left;
                color: #333333;
                word-break: break-all;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .gd {
                text-align: right;
                span {
                  color: #d20505;
                  font-weight: bold;
                }
              }
              > p:not(:last-child):hover {
                color: #d20505;
              }
            }
          }
        }
        > div:hover {
          box-shadow: 0px 0px 10px 0px rgba(210, 5, 5, 0.32);
          transform: scale(1.05);
          z-index: 1;
          overflow: hidden;
          transition: 0.5s;
        }
      }
    }
  }
  .introduce {
    width: 100%;
    padding: 40px;
    background: rgba(210, 5, 5, 0.05);
    .content {
      margin: auto;
      display: flex;
      > :first-child {
        position: relative;
        > img {
          width: 100%;
          height: auto;
        }
        > div {
          position: absolute;
          bottom: 0;
          background: rgba(0, 0, 0, 0.7);
          width: 100%;
          padding: 10px;
          > p {
            margin: 0;
          }
          > :first-child {
            font-size: 14px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            color: #ffffff;
            margin-bottom: 5px;
          }
          > :last-child {
            font-size: 10px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            color: #ffffff;
          }
        }
      }
      > :nth-child(2) {
        position: relative;
        > img {
          width: 100%;
          height: auto;
        }
        > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 15px;
          > :first-child {
            font-size: 18px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            text-align: left;
            color: #837e75;
          }
          > :nth-child(2) {
            width: 300px;
            height: 51px;
            font-size: 12px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            color: #9e978b;
            line-height: 32px;
            > span {
              margin-left: 20px;
              a {
                color: #d20505;
              }
            }
          }
          > ul {
            list-style: none;
            display: flex;
            padding: 0;
            justify-content: space-between;
            width: 300px;
            border-top: 2px solid rgb(206, 196, 196);
            margin-top: 15px;
            padding-top: 15px;
            > li {
              > :first-child {
                font-size: 20px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                color: #d20505;
              }
              > :last-child {
                font-size: 12px;
                font-family: Microsoft YaHei, Microsoft YaHei-Regular;
                color: #9e978b;
              }
            }
          }
        }
      }
      > :last-child {
        > img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>

<style scoped>
</style>
