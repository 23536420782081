<template>
  <div id="app">
    <my-header v-if="$route.name != 'error'" />
    <router-view />
    <my-footer v-if="$route.name != 'error'" />
  </div>
</template>

<script>
export default {
  mounted() {
    // console.log = () => {};
  },
  watch: {
    //切换页面时 返回最顶部
    $route() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="less">
@import "./style/reset.css";
@media screen and(max-width: 900px) {
  html {
    font-size: 8px !important;
  }
}
html {
  font-size: 10px;
}
</style>
