import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import lottie from "vue-lottie";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Banner from "@/components/banner"; //顶部图片与二级导航栏
import NewNotice from "@/components/newNotice"; //新闻列表页面
import searchPopup from "@/components/searchPopup"; //新闻列表页面

import "animate.css";
import "./plugins/antdUI";

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);
// 配置项
Vue.use(VueLazyload, {
  preLoad: 1.5,
  // error: "dist/error.png",
  // loading: "dist/loading.gif",
  attempt: 1,
});

const ElArr = [Header, Footer, Banner, NewNotice, searchPopup];
for (let i of ElArr) {
  Vue.component(i.name, i);
}
Vue.component("lottie", lottie);
Vue.prototype.axios = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
