import axios from "axios";

// const baseUrl = "http://192.168.87.155:8001";
// const baseUrl = "/yifangda";
const baseUrl = "/yifangda";

//获取新闻列表
export const getData = (params) => {
  return axios({
    url: `${baseUrl}/ow/list`,
    method: "POST",
    params,
  });
};

//获取新闻详情
export const getDetails = (params) => {
  return axios({
    url: `${baseUrl}/ow/getSysNotice`,
    method: "POST",
    params,
  });
};

/**
 * 页面管理接口
 * 管理【关于我们】页面的“公司介绍”，和【服务体系】页面的“物业服务”，“供热供水”，“宜民优选”，“便民服务”，“工程建设”，
 * 共6个页面；管理这六个页面的图片和文字；
 *
 * 注意：“公司介绍”页面只有一个图片；其它页面都有6个图片
 *
 * 参数：
 * pageName： 页面名称，可传入，“公司介绍”，“物业服务”，“供热供水”，“宜民优选”，“便民服务”，“工程建设”，不传入查询该六个页面
 */
export const queryPageManageList = (params) => {
  return axios({
    url: `${baseUrl}/system/pageManage/list`,
    method: "POST",
    params,
  });
};

/**
 * 图片管理接口
 * 管理“首页”，“关于我们”，“服务项目”，“服务体系”，“新闻动态”，“人才需求”，“党建工作”的banner图；
 * 和【关于我们】的“员工风采”页面的展示图
 *
 * 参数：
 * photoPage： 所属页面，“首页”，“关于我们”，“服务项目”，“服务体系”，“新闻动态”，“人才需求”，”员工风采“
 * photoType
 */
export const queryPhotoManageList = (params) => {
  return axios({
    url: `${baseUrl}/system/photoManage/list`,
    method: "POST",
    params,
  });
};

/**
 * 人才需求接口
 */
export const queryTalentList = () => {
  return axios({
    url: `${baseUrl}/system/talent/list`,
    method: "POST",
  });
};

/**
 * 企业文化
 */
export const queryCultureOfFirmList = () => {
  return axios({
    url: `${baseUrl}/system/pageManage/cultureOfFirmList`,
    method: "POST",
  });
};

/**
 * 发展历程
 */
export const queryDevelopmentPathList = () => {
  return axios({
    url: `${baseUrl}/system/pageManage/developmentPathList`,
    method: "POST",
  });
};
